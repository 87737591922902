body {
  font: 3vmin "Jura", sans-serif;
  margin: 20px;
  background-color: #000000;
  color: #EFEFEF;
}

h3 {
  margin-bottom: 5px;
}

a:link,
a:visited {
  color: #EFEFEF;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: transparent;
  border: 1px solid transparent;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 7vmin;
  padding: 0;
  text-align: center;
  width: 7vmin;
  background-repeat: no-repeat;
  background-size: 7vmin 7vmin;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game-board {
  margin-top: 8vmin;
}

.logo {
  position: relative;
  left: 4vmin;
  top: 4vmin;
  font-size: 6vmin;
}

.subheader {
  position: relative;
  left: 4vmin;
  top: 6vmin;
  font-size: 3vmin;
}

.console-text {
  font-size: 2.5vmin;
  margin-top: 1vmin;
  text-align: left;
  line-height: 3.5vmin;
}

.icons-attribution {
  margin-bottom: 20px;
  font-size: 2vmin;
}

.game-status {
  margin-bottom: 10px;
}

.fallen-soldier-block {
  margin-bottom: 20px;
}

.dark-square {
  background-color: #c38800;
}

.light-square {
  background-color: #F1D592;
}

.possible-move {
  /*background-color: #EDE1CB*/
  background-color: rgb(136, 211, 186);
}

.game {
  margin: auto;
  width: 56vmin;
}

.indiv-fallen-block {
  background-color: rgb(224, 239, 252);
  border-radius: 15px;
  margin: auto;
  width: 56vmin;
  margin-bottom: 1vh;
}

#player-turn-box {
  width: 7vmin;
  height: 7vmin;
  border: 1px solid #FFFFFF;
  margin-bottom: 10px;
}


.react-icon {
  color: cyan;
}

.heroku-icon {
  color: purple;
}

.tech-used {
  margin-bottom: 2vmin;
}

.game-bottom-box {
  display: flex;
  width: 100%;
}

.float-child:first-child {
  margin-right: 0px;
  flex: 25%;
}

.float-child:nth-child(2) {
  flex: 75%;
}